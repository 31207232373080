import React from 'react';
import ListaLocatii from '../components/lista-locatii';
import Searchbox from '../components/searchbox';

const AcasaPage = () => {
	return (
		<div className='container' style={{ maxWidth: '1800px' }}>
			<Searchbox />
			<ListaLocatii />
		</div>
	);
};

export default AcasaPage;
