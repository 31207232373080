import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useGlobalContext } from '../context';

function dateDiffInDays(a, b) {
	// Discard the time and time-zone information.
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

	return Math.floor((utc2 - utc1) / (1000 * 3600 * 24));
}

const ModalEliminare = ({
	NrAuto,
	SerieSasiu,
	Depozitare,
	Locatie,
	DataIesire,
	DataIntrare,
	Agent,
}) => {
	const { fetchLocatii, createLog } = useGlobalContext();
	const [startDate, setStartDate] = useState(new Date());

	const dataProgramareValue = useRef('');

	const dataIntrare = DataIntrare ? new Date(DataIntrare) : null;
	const dataIesire = DataIesire ? new Date(DataIesire) : null;

	const nrZilePlatite = dateDiffInDays(dataIntrare, dataIesire);
	const nrZileEfective = dateDiffInDays(dataIntrare, startDate);
	const difZile = nrZileEfective - nrZilePlatite;

	const handleProgramare = async () => {
		await fetch(`${process.env.REACT_APP_API_URL}/api/programare/${Locatie}`, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				data_programare_iesire: dataProgramareValue.current.input.value,
			}),
		});

		await fetch(
			`https://vibrant-murdock-8a3ded.netlify.app/api/express/programare-eliberare/`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					locatie: Locatie,
					nr_auto: NrAuto,
					serie_sasiu: SerieSasiu,
					depozitare: Depozitare,
					data_programare_iesire: dataProgramareValue.current.input.value,
					agent: Agent,
				}),
			}
		);

		await createLog('PROG ELIBERARE', {
			locatie: Locatie,
			nrauto: NrAuto,
			seriesasiu: SerieSasiu,
			dataintrare: DataIntrare,
			dataiesire: DataIesire,
			dataprogramareiesire: dataProgramareValue.current.input.value,
			depozitare: Depozitare,
			deviz: null,
			agent: Agent,
		});

		// await fetch(`${process.env.REACT_APP_API_URL}/api/mail-programare`, {
		// 	method: 'POST',
		// 	headers: { 'Content-Type': 'application/json' },
		// 	body: JSON.stringify({
		// 		locatie: Locatie,
		// 		nr_auto: NrAuto,
		// 		serie_sasiu: SerieSasiu,
		// 		depozitare: Depozitare,
		// 		data_programare_iesire: dataProgramareValue.current.input.value,
		// 		agent: Agent,
		// 	}),
		// });

		await fetchLocatii();
	};
	return (
		<div
			className='modal fade'
			id={`modal${Locatie}`}
			tabIndex='-1'
			aria-labelledby='exampleModalLabel'
			aria-hidden='true'>
			<div className='modal-dialog modal-dialog-centered'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h6 className='modal-title' id='exampleModalLabel'>
							Confirmare eliberare
						</h6>
						<button
							type='button'
							className='btn-close'
							data-bs-dismiss='modal'
							aria-label='Close'></button>
					</div>
					<div className='modal-body'>
						Confirm ca locatia <code className='fs-6'>{Locatie}</code>, unde se
						depoziteaza <code className='fs-6'>{Depozitare}le</code> pentru auto cu nr.{' '}
						<code className='fs-6'>{NrAuto}</code> (
						<code className='fs-6'>{SerieSasiu}</code>), trebuie eliberata.
						<div className='mt-4 mb-2 d-flex justify-content-between align-items-center'>
							<label
								htmlFor='data-intrare'
								className='form-label align-self-center mb-0'>
								Data programare eliberare:
							</label>
							<DatePicker
								selected={startDate}
								onChange={(date) => setStartDate(date)}
								ref={dataProgramareValue}
								dateFormat='yyyy-MM-dd'
								className='form-control'
								name='dataprog'
							/>
						</div>
						{difZile > 0 ? (
							<p className='alert alert-danger mt-4'>
								<strong>Atentie!</strong> Data selectata este mai indepartata cu{' '}
								<strong>{difZile}</strong> zile fata de cea platita. Clientul va
								trebui sa achite o diferenta de{' '}
								<strong>
									{(difZile * (Depozitare === 'anvelope' ? 1 : 1.2)).toFixed(2)}
								</strong>{' '}
								RON.
							</p>
						) : (
							difZile < 0 && (
								<p className='alert alert-info mt-4'>
									Data selectata este mai apropiata cu{' '}
									<strong>{difZile * -1}</strong> zile fata de cea platita. Va
									trebui sa-i restitui clientului o diferenta de{' '}
									<strong>
										{(
											difZile *
											-1 *
											(Depozitare === 'anvelope' ? 1 : 1.2)
										).toFixed(2)}
									</strong>{' '}
									RON.
								</p>
							)
						)}
					</div>
					<div className='modal-footer'>
						<button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
							Inchide
						</button>
						<button
							type='button'
							onClick={handleProgramare}
							className='btn btn-warning'
							data-bs-dismiss='modal'>
							Programeaza eliberarea
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalEliminare;
