export const adminRole = [
   '13F50887-FE91-4DE4-85BC-19BD2DB11ABC',
   'ED62FF29-388F-4472-8BEC-FC13C1832A20',
   '18386618-7A23-4FEA-8A98-B33FEA0169E1',
];

export const depozitRole = [
   '2E7E907A-B89C-4CAD-84E1-2DFBF08A11D4',
   'F1C6CC63-DE5A-471B-A12B-A768421BC32F',
   'A48B79A5-DB2F-4DBC-B850-0477E423C22E',
];

export const agentRole = [
   '6ED8E397-6C62-40CE-9385-D0FE6A8370D0',
   'D287F8E2-3DBC-4247-AEE5-F912CD29E6FB',
];
