import React from 'react';
import { useGlobalContext } from '../context';
import LocatieComp from './locatie';

// const Locatie = React.lazy(() => import('./Locatie'));

const ListaLocatii = () => {
	const { locatii, search, agentSingle } = useGlobalContext();

	const filteredLocatii = locatii.filter((loc) => {
		if (search.length > 2) {
			return (
				(loc.NrAuto && loc.NrAuto.toLowerCase().includes(search.toLowerCase())) ||
				(loc.SerieSasiu && loc.SerieSasiu.toLowerCase().includes(search.toLowerCase()))
			);
		}

		if (agentSingle !== 'toti') {
			return loc.Agent === agentSingle;
		}

		return (
			(loc.NrAuto &&
				new Date(
					new Date().setTime(
						new Date(loc.DataIesire).getTime() - 10 * 24 * 60 * 60 * 1000
					)
				) < new Date()) ||
			(loc.Status && loc.Status !== 'confirmat')
			// loc.NrAuto
		);
	});

	return (
		<section className='container-home'>
			{/* <Suspense fallback={<div>Loading...</div>}> */}
			{filteredLocatii.map((loc) => {
				return <LocatieComp key={loc.Locatie} {...loc} />;
			})}
			{/* </Suspense> */}
		</section>
	);
};

export default ListaLocatii;
