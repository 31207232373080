/* eslint-disable array-callback-return */
import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../context';
import btnAdauga from '../assets/plus.svg';
import LocuriDisponibile from './locuri-disponibile';

const Searchbox = () => {
	const { agenti, setSearch, setAgentSingle } = useGlobalContext();

	const onSearchChange = (e) => {
		setSearch(e.target.value);
	};

	const onAgentChange = (e) => {
		setAgentSingle(e.target.value);
	};

	return (
		<section className='container-searchbox'>
			<Link to='/adaugare' className='my-auto'>
				{/* <span className='text-dark'>Adauga</span> */}
				<img
					src={btnAdauga}
					alt='Adauga'
					height='35px'
					data-bs-toggle='tooltip'
					title='Depozitare noua'
				/>
			</Link>
			<form className='d-flex py-5 px-1 pe-md-5' onSubmit={(e) => e.preventDefault()}>
				<select
					class='form-select mx-3'
					onChange={onAgentChange}
					style={{ maxWidth: '300px' }}>
					<option value='toti'>Toti agentii</option>
					{agenti.map((agent) => {
						return <option value={agent}>{agent}</option>;
					})}
				</select>
				<input
					className='form-control'
					placeholder='Cauta dupa nr. auto sau serie sasiu...'
					type='search'
					name='search'
					id='search'
					autoComplete='off'
					onChange={onSearchChange}
				/>
			</form>
			<LocuriDisponibile />
		</section>
	);
};

export default Searchbox;
