import React, { useEffect, useState } from 'react';
import Istoric from './istoric';

const ListaIstoric = () => {
	const [istoric, setIstoric] = useState([]);

	const getIstoric = async () => {
		const res = await fetch(`${process.env.REACT_APP_API_URL}/api/istoric`);
		const data = await res.json();
		setIstoric(data);
	};

	useEffect(() => {
		getIstoric();
	}, []);

	// const filteredLocatii = locatii.filter((locatie) => {
	// 	if (search.length > 0) {
	// 		return (
	// 			(locatie.nr_auto && locatie.nr_auto.toLowerCase().includes(search.toLowerCase())) ||
	// 			(locatie.serie_sasiu &&
	// 				locatie.serie_sasiu.toLowerCase().includes(search.toLowerCase()))
	// 		);
	// 	}
	// 	return locatie;
	// });

	return (
		<section className='container-istoric'>
			{istoric.map((locatie) => {
				return <Istoric key={locatie.IdLog} {...locatie} />;
			})}
		</section>
	);
};

export default ListaIstoric;
