import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Navbar from './components/navbar';
// import ProtectedRoute from './components/protected-route';
import AcasaPage from './pages/acasa-page';
import AdaugarePage from './pages/adaugare-page';
import ConfirmarePage from './pages/confirmare-page';
import IstoricPage from './pages/istoric-page';
import LoginPage from './pages/login-page';
import TaskuriPage from './pages/taskuri-page';

const App = () => {
	return (
		<>
			{/* <Navbar />
			<Switch>
				<ProtectedRoute exact path='/'>
					<AcasaPage />
				</ProtectedRoute>
				<ProtectedRoute path='/taskuri'>
					<TaskuriPage />
				</ProtectedRoute>
				<ProtectedRoute path='/adaugare'>
					<AdaugarePage />
				</ProtectedRoute>
				<ProtectedRoute path='/confirmare/:id-:locatie-:status'>
					<ConfirmarePage />
				</ProtectedRoute>
				<Route path='/login'>
					<LoginPage />
				</Route>
				<Route path='*'>
					<div>Not Found</div>
				</Route>
			</Switch> */}
			<Navbar />
			<Switch>
				<Route exact path='/'>
					<AcasaPage />
				</Route>
				<Route path='/taskuri'>
					<TaskuriPage />
				</Route>
				<Route path='/istoric'>
					<IstoricPage />
				</Route>
				<Route path='/adaugare'>
					<AdaugarePage />
				</Route>
				<Route path='/confirmare/:locatie-:status'>
					<ConfirmarePage />
				</Route>
				<Route path='/login'>
					<LoginPage />
				</Route>
				<Route path='*'>
					<div>Not Found</div>
				</Route>
			</Switch>
		</>
	);
};

export default App;
