/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import QrReader from 'react-qr-reader';
import { useHistory, useParams } from 'react-router';
import { useGlobalContext } from '../context';

const ConfirmarePage = () => {
	const history = useHistory();
	const { fetchLocatii, authenticatedUser } = useGlobalContext();
	const { locatie, status } = useParams();
	const [result, setResult] = useState('No result');

	const handleScan = (data) => {
		if (data !== null) {
			setResult(data);
		}
		// console.log(data);
	};

	const handleError = (error) => {
		console.log(error);
	};

	const previewStyle = {
		width: '100%',
	};

	const adaugaLocatie = async () => {
		await fetch(`${process.env.REACT_APP_API_URL}/api/adaugare/${locatie}`, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
		});

		await fetchLocatii();
	};

	const elibereazaLocatie = async () => {
		await fetch(`${process.env.REACT_APP_API_URL}/api/eliberare/${locatie}`, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				username: authenticatedUser.username,
			}),
		});

		await fetchLocatii();
	};

	useEffect(() => {
		if (result === locatie && status === 'trebuie_depozitata') {
			adaugaLocatie();
			history.push('/taskuri');
		}

		if (result === locatie && status === 'trebuie_eliberata') {
			elibereazaLocatie();
			history.push('/taskuri');
		}
		// console.log(result);
	}, [result, history, locatie]);

	// console.log(result);

	return (
		<div>
			<QrReader
				delay={500}
				style={previewStyle}
				onError={handleError}
				onScan={handleScan}
				facingMode={'environment'}
			/>
			{result !== locatie && result !== 'No result' && (
				<h5 className='d-flex flex-column justify-content-center align-items-center mt-4 mx-3 '>
					<span className='text-center'>
						Nu esti la locatia potrivita. Trebuie sa mergi la
					</span>
					<mark className='fw-bold mono mt-2 py-2 fs-2'>{locatie}</mark>
				</h5>
			)}
		</div>
	);
};

export default ConfirmarePage;
