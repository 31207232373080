import React from 'react';
import { useGlobalContext } from '../context';

const LocuriDisponibile = () => {
   const { locatii } = useGlobalContext();

   const anvelope = locatii.filter(
      (loc) => !loc.NrAuto && (loc.Etaj === 3 || loc.Etaj === 4)
   ).length;

   const roti = locatii.filter((loc) => !loc.NrAuto && (loc.Etaj === 1 || loc.Etaj === 2)).length;

   return (
      <article className='alert alert-warning mt-3 mb-3 d-none d-md-block'>
         <h5>Nr. locatii disponibile</h5>
         <p className='mb-0'>
            Anvelope: <strong>{anvelope}</strong>
         </p>
         <p className='mb-0'>
            Roti: <strong>{roti}</strong>
         </p>
      </article>
   );
};

export default LocuriDisponibile;
