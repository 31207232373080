import React from 'react';

function dateDiffInDays(a, b) {
	// Discard the time and time-zone information.
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

	return Math.floor((utc2 - utc1) / (1000 * 3600 * 24));
}

const Istoric = ({
	NrAuto,
	SerieSasiu,
	Depozitare,
	DataIntrare,
	DataIesire,
	DataProgramareIesire,
	Agent,
	Locatie,
}) => {
	const dataIntrare = DataIntrare ? new Date(DataIntrare) : null;
	const dataIesire = DataIesire ? new Date(DataIesire) : null;
	const dataProgramareIesire = DataProgramareIesire ? new Date(DataProgramareIesire) : null;

	const nrZilePlatite = dateDiffInDays(dataIntrare, dataIesire);
	const nrZileEfective = dateDiffInDays(dataIntrare, dataProgramareIesire);
	const difZile = nrZileEfective - nrZilePlatite;

	const culoareCard = difZile > 0 ? 'danger' : difZile < 0 && 'info';

	return (
		<article className='mb-3' style={{ minHeight: '280px' }}>
			<div className={`card alert-${culoareCard} h-100`} style={{ width: '20rem' }}>
				{/* HEADER */}
				<div className='card-header'>
					<h5 className='text-center mt-2 mb-2 mono fw-bold'>{NrAuto}</h5>
				</div>

				{/* BODY */}
				<div className='card-body'>
					<table className={`table fs-7 alert-${culoareCard} table-borderless mb-0`}>
						<tbody>
							<tr>
								<td className='p-0'>Serie sasiu:</td>
								<td className='p-0 text-end fw-bold mono'>{SerieSasiu}</td>
							</tr>
							<tr>
								<td className='p-0'>Depozitare:</td>
								<td className='p-0 text-end fw-bold mono title-case'>
									{Depozitare}
								</td>
							</tr>
							<tr>
								<td className='p-0'>Data intrare:</td>
								<td className='p-0 text-end fw-bold mono'>
									{dataIntrare.toLocaleDateString()}
								</td>
							</tr>
							<tr>
								<td className='p-0'>Perioada platita:</td>
								<td className='p-0 text-end fw-bold mono'>
									{dataIesire.toLocaleDateString()}
								</td>
							</tr>
							<tr>
								<td className='p-0'>Nr. zile platite:</td>
								<td className='p-0 text-end fw-bold mono'>{nrZilePlatite}</td>
							</tr>
							<tr>
								<td className='p-0'>Data iesire efectiva:</td>
								<td className='p-0 text-end fw-bold mono'>
									{dataProgramareIesire.toLocaleDateString()}
								</td>
							</tr>
							<tr>
								<td className='p-0'>Nr. zile efective:</td>
								<td className='p-0 text-end fw-bold mono'>{nrZileEfective}</td>
							</tr>
							<tr>
								<td className='p-0'>Agent:</td>
								<td
									className='p-0 text-end fw-bold mono'
									style={{ maxWidth: '126px' }}>
									{Agent}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				{difZile > 0 ? (
					<p className='mono fs-7 text-center mark px-2'>
						<span className='capitalize'>{Depozitare}</span>le au fost ridicate mai
						tarziu cu <strong>{difZile}</strong> zile fata de perioada platita. Clientul
						trebuie sa achite o diferenta de{' '}
						<strong>{difZile * (Depozitare === 'anvelope' ? 1 : 1.2)}</strong> RON.
					</p>
				) : (
					difZile < 0 && (
						<p className='mono fs-7 text-center mark px-2'>
							Clientul a ridicat {Depozitare}le mai devreme cu{' '}
							<strong>{difZile * -1}</strong> zile fata de perioada platita. Trebuie
							sa-i fie restituita o diferenta de{' '}
							<strong>{difZile * -1 * (Depozitare === 'anvelope' ? 1 : 1.2)}</strong>{' '}
							RON.
						</p>
					)
				)}

				{/* FOOTER */}
				<div className='card-footer d-flex justify-content-center'>
					<h5 className='mb-0 fw-900 mono'>{Locatie}</h5>
				</div>
			</div>
		</article>
	);
};

export default Istoric;
