/* eslint-disable array-callback-return */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const [authenticatedUser, setAuthenticatedUser] = useState({
		username: '',
		nume: '',
		role: '',
	});
	const [loginError, setLoginError] = useState(false);
	const [agentSingle, setAgentSingle] = useState('toti');

	// fetch locatii
	const [locatii, setLocatii] = useState([]);

	const fetchLocatii = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/locatii`);
			const data = await response.json();
			setLocatii(data);
		} catch (error) {
			console.log(error.message);
		}
		// console.log('fetch done');
	};

	//agenti
	const agenti = [];
	locatii.map((loc) => {
		if (!loc.Agent) return;
		if (agenti.includes(loc.Agent)) {
			return;
		} else {
			return agenti.push(loc.Agent);
		}
	});

	// check if user is authenticated
	const checkAuth = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/check-auth`, {
				credentials: 'include',
			});
			const data = await response.json();
			// console.log(data);

			if (data.username) {
				setAuthenticatedUser(data);
			} else {
				history.push('/login');
				// <Redirect to='/login' />;
				// console.log('eroare');
			}
		} catch (error) {
			console.log('checkauth error', error);
		}
	};
	// console.log(authenticatedUser);

	// login

	const handleLogin = async (username, password) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify({
					username,
					password,
				}),
			});
			const user = await response.json();
			if (!user.username) {
				setLoginError(true);
				console.log('User inexistent');
			} else {
				const userData = {
					username: user.username,
					nume: user.nume,
					role: user.role,
				};
				// console.log(userData);
				setAuthenticatedUser(userData);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// logs
	const createLog = async (tip, info) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-log`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					username: authenticatedUser.username,
					tip,
					info,
				}),
			});
			return response.status;
		} catch (error) {
			console.log('eroare creare log');
		}
	};

	// useEffects
	useEffect(() => {
		if (authenticatedUser.username) {
			fetchLocatii();
		} else {
			checkAuth();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authenticatedUser.username]);

	return (
		<AppContext.Provider
			value={{
				loading,
				locatii,
				setSearch,
				search,
				fetchLocatii,
				handleLogin,
				authenticatedUser,
				setAuthenticatedUser,
				loginError,
				setLoginError,
				createLog,
				agenti,
				agentSingle,
				setAgentSingle,
			}}>
			{children}
		</AppContext.Provider>
	);
};

export const useGlobalContext = () => {
	return useContext(AppContext);
};

export { AppContext, AppProvider };
