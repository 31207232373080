import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import { useGlobalContext } from '../context';

const LoginPage = () => {
	const { handleLogin } = useGlobalContext();
	const history = useHistory();
	const usernameValue = useRef('');
	const passwordValue = useRef('');

	const handleSubmit = async () => {
		await handleLogin(usernameValue.current.value, passwordValue.current.value);
		// await fetchLocatii();
		history.push('/');
	};

	const handleEnter = (e) => {
		if (e.key === 'Enter') {
			handleSubmit();
		}
	};

	return (
		<main
			className='d-flex justify-content-center align-items-center'
			style={{ height: '100vh' }}>
			<div className='card alert-warning px-3 py-3 mx-3'>
				<div className='card-body d-flex flex-column'>
					<div className='navbar-brand text-center mb-5'>DAB Wheels</div>
					<div className='mb-3 row'>
						<label htmlFor='username' className='col-sm-3 col-form-label'>
							User:
						</label>
						<div className='col-sm-9'>
							<input
								type='text'
								className='form-control'
								id='username'
								ref={usernameValue}
							/>
						</div>
					</div>
					<div className='row'>
						<label htmlFor='password' className='col-sm-3 col-form-label'>
							Parola:
						</label>
						<div className='col-sm-9'>
							<input
								type='password'
								className='form-control'
								id='password'
								onKeyDown={handleEnter}
								ref={passwordValue}
							/>
						</div>
					</div>
					<button onClick={() => handleSubmit()} className='btn btn-warning mt-5'>
						Login
					</button>
				</div>
			</div>
		</main>
	);
};

export default LoginPage;
