import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router';

import '../assets/datepicker.css';
import * as roles from '../assets/roles';
import { useGlobalContext } from '../context';

const AdaugarePage = () => {
	// refs
	const nrDevizValue = useRef('');
	const nrAutoValue = useRef('');
	const serieSasiuValue = useRef('');
	const depozitareValue = useRef('');
	const dataIntrareValue = useRef('');
	const zileValue = useRef('');
	const agentValue = useRef('');

	const [mailAgent, setMailAgent] = useState('');
	const [disableBtn, setDisableBtn] = useState(false);

	// context
	const history = useHistory();
	const { fetchLocatii, authenticatedUser, createLog } = useGlobalContext();

	// state datepicker
	const [startDate, setStartDate] = useState(new Date());

	const getDateDeviz = async (e) => {
		if (e.key === 'Enter') {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/api/deviz/${nrDevizValue.current.value}`
				);
				const deviz = await response.json();
				const { nrauto, seriesasiu, agent, mailagent, depozitare, nrzile } = deviz;
				// console.log(deviz);

				nrAutoValue.current.value = nrauto;
				serieSasiuValue.current.value = seriesasiu;
				agentValue.current.value = agent;
				depozitareValue.current.value = depozitare === 'DPA' ? 'anvelope' : 'roti';
				zileValue.current.value = parseInt(nrzile);
				setMailAgent(mailagent);
			} catch (error) {
				console.log(error.message);
			}
		}
	};

	// calculari date
	const getDataIesire = () => {
		const date = getDataIntrare();
		const zile = Number(zileValue.current.value);
		date.setDate(date.getDate() + zile);
		return date.toLocaleDateString('fr-CA');
	};

	const getDataIntrare = () => {
		return new Date(dataIntrareValue.current.input.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setDisableBtn(true);

		await fetch(`https://vibrant-murdock-8a3ded.netlify.app/api/express/depozitare-noua/`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				nr_auto: nrAutoValue.current.value,
				serie_sasiu: serieSasiuValue.current.value,
				depozitare: depozitareValue.current.value,
				data_intrare: dataIntrareValue.current.input.value,
				agent: agentValue.current.value,
			}),
		});

		// const mailRes = await fetch(
		// 	`https://europe-west3-dab-wheels-api.cloudfunctions.net/mail/depozitare-noua`,
		// 	{
		// 		method: 'POST',
		// 		headers: { 'Content-Type': 'application/json' },
		// 		body: JSON.stringify({
		// 			nr_auto: nrAutoValue.current.value,
		// 			serie_sasiu: serieSasiuValue.current.value,
		// 			depozitare: depozitareValue.current.value,
		// 			data_intrare: dataIntrareValue.current.input.value,
		// 			agent: agentValue.current.value,
		// 		}),
		// 	}
		// );

		const adaugareRes = await fetch(`${process.env.REACT_APP_API_URL}/api/adauga`, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				id_deviz: nrDevizValue.current.value,
				nr_auto: nrAutoValue.current.value,
				serie_sasiu: serieSasiuValue.current.value,
				data_intrare: dataIntrareValue.current.input.value,
				data_iesire: getDataIesire(),
				depozitare: depozitareValue.current.value,
				agent: agentValue.current.value,
				mail_agent: mailAgent,
				status: 'trebuie_depozitata',
			}),
		});

		const logRes = await createLog('ADAUGARE', {
			locatie: null,
			nrauto: nrAutoValue.current.value,
			seriesasiu: serieSasiuValue.current.value,
			dataintrare: dataIntrareValue.current.input.value,
			dataiesire: getDataIesire(),
			dataprogramareiesire: null,
			depozitare: depozitareValue.current.value,
			deviz: nrDevizValue.current.value,
			agent: agentValue.current.value,
		});

		// const mailRes = await fetch(`${process.env.REACT_APP_API_URL}/api/mail-depozitare-noua`, {
		// const mailRes = await fetch(
		// 	`https://vibrant-murdock-8a3ded.netlify.app/api/mail-depozitare-noua`,
		// 	{
		// 		method: 'POST',
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 		},
		// 		credentials: 'include',
		// 		body: JSON.stringify({
		// 			nr_auto: nrAutoValue.current.value,
		// 			serie_sasiu: serieSasiuValue.current.value,
		// 			depozitare: depozitareValue.current.value,
		// 			data_intrare: dataIntrareValue.current.input.value,
		// 			agent: agentValue.current.value,
		// 		}),
		// 	}
		// );

		// if (adaugareRes.status === 200 && logRes === 200 && mailRes.status === 200) {
		if (adaugareRes.status === 200 && logRes === 200) {
			nrDevizValue.current.value = '';
			nrAutoValue.current.value = '';
			serieSasiuValue.current.value = '';
			depozitareValue.current.value = '';
			zileValue.current.value = '';
			agentValue.current.value = '';

			history.push('/');

			await fetchLocatii();
		} else {
			alert('eroare');
		}
	};

	return (
		<main className='d-flex flex-column justify-content-center align-items-center mt-5 h-100'>
			{roles.agentRole.includes(authenticatedUser.role) ||
			roles.adminRole.includes(authenticatedUser.role) ? (
				<div className='w-50 mt-5 mb-5'>
					<div className='mb-3'>
						<label htmlFor='id_deviz' className='form-label'>
							Nr. deviz
						</label>
						<input
							type='text'
							ref={nrDevizValue}
							onKeyDown={getDateDeviz}
							className='form-control'
							id='id_deviz'
							placeholder='Apasa Enter dupa ce copiezi nr-ul de deviz aici...'
							autoComplete='off'
							required
						/>
					</div>
					<div className='mb-3'>
						<label htmlFor='nr-auto' className='form-label'>
							Nr. auto
						</label>
						<input
							type='text'
							className='form-control'
							autoComplete='off'
							id='nr-auto'
							ref={nrAutoValue}
							required
						/>
					</div>
					<div className='mb-3'>
						<label htmlFor='serie-sasiu' className='form-label'>
							Serie sasiu
						</label>
						<input
							type='text'
							ref={serieSasiuValue}
							className='form-control'
							id='serie-sasiu'
							autoComplete='off'
							required
						/>
					</div>
					<div className='mb-3'>
						<label htmlFor='depozitare' className='form-label'>
							Tip depozitare
						</label>
						<select
							required
							className='form-select'
							ref={depozitareValue}
							aria-label='Default select example'>
							<option value='anvelope'>Anvelope</option>
							<option value='roti'>Roti</option>
						</select>
					</div>
					<div className='mb-3'>
						<label htmlFor='data-intrare' className='form-label'>
							Data intrare
						</label>
						<br />
						<DatePicker
							selected={startDate}
							onChange={(date) => setStartDate(date)}
							dateFormat='yyyy-MM-dd'
							ref={dataIntrareValue}
							className='form-control'
						/>
						<span className='text-danger ms-3'>Nu uita sa alegi data intrarii!</span>
					</div>
					<div className='mb-3'>
						<label htmlFor='zile-depozitare' className='form-label'>
							Nr. zile depozitare
						</label>
						<input
							type='number'
							ref={zileValue}
							className='form-control'
							id='zile-depozitare'
							required
						/>
					</div>
					<div className='mb-3'>
						<label htmlFor='agent' className='form-label'>
							Agent
						</label>
						<input
							required
							type='text'
							className='form-control-plaintext'
							autoComplete='off'
							id='agent'
							ref={agentValue}
						/>
					</div>
					<button
						type='button'
						onClick={handleSubmit}
						className={disableBtn ? 'btn btn-warning disabled' : 'btn btn-warning'}>
						Adauga
					</button>
				</div>
			) : (
				<p className='alert alert-danger'>
					<span className='fw-bold'>Nu ai drepturile necesare</span>. Nu poti accesa
					aceasta pagina!
				</p>
			)}
		</main>
	);
};

export default AdaugarePage;
