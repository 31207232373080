import React from 'react';
import ListaTaskuri from '../components/lista-taskuri';
import { useGlobalContext } from '../context';
import * as roles from '../assets/roles';

const TaskuriPage = () => {
	const { authenticatedUser } = useGlobalContext();

	return (
		<>
			{roles.depozitRole.includes(authenticatedUser.role) ||
			roles.adminRole.includes(authenticatedUser.role) ? (
				<ListaTaskuri />
			) : (
				<main className='d-flex flex-column justify-content-center align-items-center mt-5'>
					<p className='alert alert-danger'>
						<span className='fw-bold'>Nu ai drepturile necesare</span>. Nu poti accesa
						aceasta pagina!
					</p>
				</main>
			)}
		</>
	);
};

export default TaskuriPage;
