import React, { useEffect, useState } from 'react';
import close from '../assets/close.svg';
import ModalEliminare from './modal-eliminare';
import 'bootstrap/js/src/modal';

const LocatieComp = ({
   Locatie,
   NrAuto,
   SerieSasiu,
   DataIntrare,
   DataIesire,
   Agent,
   Depozitare,
   Deviz,
   Status,
   MailDepasire,
   MailAgent,
}) => {
   const dataIntrare = DataIntrare && new Date(DataIntrare).toLocaleDateString();
   const dataIesire = DataIesire && new Date(DataIesire).toLocaleDateString();

   // const [stare, setStare] = useState(Status);
   const [showModal, setShowModal] = useState(false);
   // eslint-disable-next-line no-unused-vars
   const [dataPreDepasire, setDataPreDepasire] = useState(
      new Date(new Date().setTime(new Date(DataIesire).getTime() - 10 * 24 * 60 * 60 * 1000))
   );

   // console.log();
   // console.log(new Date(DataIesire), '-', new Date());
   // const dataPreDepasire = new Date(new Date().setDate(new Date(DataIesire).getDate() - 10));
   // console.log(dataPreDepasire);
   // console.log(new Date(DataIesire));

   const checkDepasire = async () => {
      if (dataPreDepasire < new Date()) {
         if (MailDepasire === null) {
            const setMailRes = await fetch(
               `${process.env.REACT_APP_API_URL}/api/set-mail-depasire`,
               {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ locatie: Locatie }),
               }
            );
            const setMail = await setMailRes.json();
            console.log(setMail);
            if (setMail.MailDepasire === 'y' && setMail.MailAgent !== null) {
               await fetch(`https://vibrant-murdock-8a3ded.netlify.app/api/express/depasire/`, {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                     nr_auto: NrAuto,
                     serie_sasiu: SerieSasiu,
                     depozitare: Depozitare,
                     data_iesire: DataIesire,
                     mail_agent: MailAgent,
                  }),
               });
            }
         }
      }
   };

   useEffect(() => {
      checkDepasire();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <article style={{ minHeight: '280px' }}>
         {!NrAuto ? (
            // daca locatia e libera
            <div className='card alert-secondary h-100' style={{ width: '15rem' }}>
               {/* HEADER */}
               {/* BODY */}
               <div className='card-body d-flex justify-content-center align-items-center'>
                  <h3 className='card-title mb-0'>LIBER</h3>
               </div>

               {/* FOOTER */}
               <div className='card-footer d-flex justify-content-center'>
                  <h5 className='mb-0 fw-900 mono'>{Locatie}</h5>
               </div>
            </div>
         ) : (
            // daca locatia e ocupata
            <div
               className={`card alert-${
                  Status === 'trebuie_depozitata'
                     ? 'info'
                     : Status === 'trebuie_eliberata'
                     ? 'dark'
                     : MailDepasire === 'y'
                     ? 'danger'
                     : 'warning'
               } h-100`}
               style={{ width: '15rem' }}
            >
               {/* HEADER */}
               <div className='card-header'>
                  <h5 className='text-center mt-2 mb-2 mono fw-bold'>{NrAuto}</h5>
               </div>

               {/* BODY */}
               <div className='card-body'>
                  <table
                     className={`table fs-7 alert-${
                        Status === 'trebuie_depozitata'
                           ? 'info'
                           : Status === 'trebuie_eliberata'
                           ? 'dark'
                           : MailDepasire === 'y'
                           ? 'danger'
                           : 'warning'
                     } table-borderless mb-0`}
                  >
                     <tbody>
                        <tr>
                           <td className='p-0'>Nr. deviz:</td>
                           <td className='p-0 text-end fw-bold mono'>{Deviz}</td>
                        </tr>
                        <tr>
                           <td className='p-0'>Serie sasiu:</td>
                           <td className='p-0 text-end fw-bold mono'>{SerieSasiu}</td>
                        </tr>
                        <tr>
                           <td className='p-0'>Depozitare:</td>
                           <td className='p-0 text-end fw-bold mono title-case'>{Depozitare}</td>
                        </tr>
                        <tr>
                           <td className='p-0'>Data intrare:</td>
                           <td className='p-0 text-end fw-bold mono'>{dataIntrare}</td>
                        </tr>
                        <tr>
                           <td className='p-0'>Data iesire:</td>
                           <td className='p-0 text-end fw-bold mono'>{dataIesire}</td>
                        </tr>
                        <tr>
                           <td className='p-0'>Agent:</td>
                           <td className='p-0 text-end fw-bold mono' style={{ maxWidth: '126px' }}>
                              {Agent}
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
               {Status === 'trebuie_depozitata' ? (
                  <p className='mono fs-7 text-center mark'>PENDING: TREBUIE DEPOZITATA</p>
               ) : Status === 'trebuie_eliberata' ? (
                  <p className='mono fs-7 text-center mark'>PENDING: URMEAZA SA SE ELIBEREZE</p>
               ) : (
                  MailDepasire === 'y' && (
                     <p className='mono fs-7 text-center mark'>EXPIRA PERIOADA DEPOZITARII!</p>
                  )
               )}

               {/* FOOTER */}
               <div className='card-footer d-flex justify-content-center'>
                  <h5 className='mb-0 fw-900 mono'>{Locatie}</h5>
               </div>
               <div data-bs-toggle='tooltip' title='Elibereaza locatia'>
                  <img
                     className='icon-elimina'
                     src={close}
                     onMouseOver={() => setShowModal(true)}
                     // onMouseLeave={() => setShowModal(false)}
                     data-bs-toggle='modal'
                     data-bs-target={`#modal${Locatie}`}
                     alt='elimina'
                  />
               </div>
               {showModal && (
                  <ModalEliminare
                     NrAuto={NrAuto}
                     SerieSasiu={SerieSasiu}
                     Depozitare={Depozitare}
                     Locatie={Locatie}
                     DataIesire={DataIesire}
                     DataIntrare={DataIntrare}
                     Agent={Agent}
                  />
               )}
            </div>
         )}
      </article>
   );
};

export default LocatieComp;
