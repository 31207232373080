import React from 'react';
import { useHistory } from 'react-router';

const Task = ({
	NrAuto,
	SerieSasiu,
	Status,
	Locatie,
	Depozitare,
	DataIntrare,
	DataProgramareIesire,
}) => {
	const history = useHistory();

	const dataProgramare = DataProgramareIesire ? new Date(DataProgramareIesire) : null;
	const dataIntrare = DataIntrare ? new Date(DataIntrare) : null;

	const handleButon = async () => {
		history.push(`/confirmare/${Locatie}-${Status}`);
	};

	return (
		<article className='mb-3'>
			<div
				className={`card alert-${
					Status === 'trebuie_depozitata' ? 'info' : 'danger'
				} h-100 rounded-0 mono pb-5 pb-sm-0`}>
				<div className='card-body d-flex flex-wrap justify-content-center  justify-content-sm-between align-items-center px-5 py-4'>
					<div>
						{Status === 'trebuie_depozitata' ? (
							<h5
								className='d-flex justify-content-between'
								style={{ marginRight: '-5px' }}>
								Depozitare: <mark>{Locatie}</mark>
							</h5>
						) : (
							<h5
								className='d-flex justify-content-between'
								style={{ marginRight: '-5px' }}>
								Eliberare: <mark>{Locatie}</mark>
							</h5>
						)}

						<table
							className={`table fs-8 alert-${
								Status === 'trebuie_depozitata' ? 'info' : 'danger'
							} table-borderless mb-0`}>
							<tbody>
								<tr>
									<td className='p-0'>Nr. auto:</td>
									<td className='p-0 text-end fw-bold'>{NrAuto}</td>
								</tr>
								<tr>
									<td className='p-0'>Serie sasiu:</td>
									<td className='p-0 text-end fw-bold' style={{ width: '150px' }}>
										{SerieSasiu}
									</td>
								</tr>
								<tr>
									<td className='p-0'>Tip:</td>
									<td className='p-0 text-end fw-bold text-uppercase'>
										{Depozitare}
									</td>
								</tr>
								{Status === 'trebuie_depozitata' && (
									<tr>
										<td className='p-0'>Data intrare:</td>
										<td className='p-0 text-end fw-bold text-uppercase'>
											<mark style={{ marginRight: '-2px' }}>
												{dataIntrare.toLocaleDateString()}
											</mark>
										</td>
									</tr>
								)}
								{Status === 'trebuie_eliberata' && (
									<tr>
										<td className='p-0'>Programata la:</td>
										<td className='p-0 text-end fw-bold text-uppercase'>
											<mark style={{ marginRight: '-2px' }}>
												{dataProgramare.toLocaleDateString()}
											</mark>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
					{Status === 'trebuie_depozitata' ? (
						<button
							onClick={handleButon}
							className='btn btn-info fw-bold px-5 rounded-0 mt-4 mt-md-0'
							type='button'
							style={{ height: '50%' }}>
							CONFIRMARE DEPOZITARE
						</button>
					) : (
						<button
							onClick={handleButon}
							className='btn btn-danger text-dark fw-bold px-5 rounded-0 mt-4 mt-md-0'
							type='button'
							style={{ height: '40%' }}>
							CONFIRMARE ELIBERARE
						</button>
					)}
				</div>
			</div>
		</article>
	);
};

export default Task;
