import React from 'react';
import ListaIstoric from '../components/lista-istoric';

const IstoricPage = () => {
	return (
		<div className='container mt-5' style={{ maxWidth: '1800px' }}>
			<ListaIstoric />
		</div>
	);
};

export default IstoricPage;
